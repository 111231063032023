import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useMocks } from "../../context"

import { useScroll } from "./../../utils/useScroll"

import {
  Container,
  AgorastoreLogo,
  PurchaseHeaderTitle,
  BackToWebsite,
  Ctas,
  ListBtn,
  RightPart,
  PurchaseHeaderContainer,
} from "./styled"

const Header = props => {
  const { scrollY, scrollDirection } = useScroll()

  const isNotOnTop = scrollY > 15

  const { header } = useMocks()

  return (
    <Container
      categories={props.categories}
      hideOnScrollDown={scrollDirection === "up" && isNotOnTop}
    >
      <AgorastoreLogo
        src="https://www.agorastore.fr/frontend/dist/assets/images/1b053a187769070dfcf9f572263e01f6-logo_blue.svg"
        alt="Purchase Guide Logo"
        hideOnScrollDown={scrollDirection === "up" && isNotOnTop}
      />

      <RightPart>
        <PurchaseHeaderContainer>
          <PurchaseHeaderTitle categories={props.categories}>
            {props.purchaseGuideTitle}
          </PurchaseHeaderTitle>
        </PurchaseHeaderContainer>

        <Ctas hideOnScrollDown={scrollDirection === "up" && isNotOnTop}>
          <a
            href="https://www.agorastore.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BackToWebsite categories={props.categories}>
              {header.back_to_agorastore}
            </BackToWebsite>
          </a>
          <span></span>
          <Link to={`/#${props.categories}`}>
            <ListBtn categories={props.categories}>{header.btn}</ListBtn>
          </Link>
        </Ctas>
      </RightPart>
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
