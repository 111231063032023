import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
  background-color: ${props => props.theme.colors.blue};

  p {
    color: ${props => props.theme.colors.white};
    max-width: 600px;
    margin: 0 auto;
    text-align: center;

    ${props => props.theme.media.mediumPlus`
      text-align:left;
      padding:0 15px;
    `}
  }

  ${props => props.theme.media.mediumPlus`
    text-align:left;

  `}
`
export const Logo = styled.img`
  margin: 50px 0;

  ${props => props.theme.media.mediumPlus`
    width:200px;
    margin-left:15px;
  `}
`

export const SponsorContainer = styled.div`
  text-align: left;

  p {
    margin-left: 37px;
    bottom: 20px;
    position: relative;

    img {
      width: 60px;
      margin-left: 10px;
    }
  }
`
export const SubContainer = styled.div`
  background-color: ${props => props.theme.colors.lightBlue};
  padding: 60px 90px 40px 90px;
  width: 80%;
  margin: 50px auto 0 auto;

  hr {
    display: none;
    background-color: ${props => props.theme.colors.white};
    opacity: 0.3;

    ${props => props.theme.media.mediumPlus`
      display:block;
    `}
  }

  ${props => props.theme.media.mediumPlus`
    width:100%;
    padding:30px 15px;
  `}

  &>div {
    ${props => props.theme.media.mediumPlus`
      flex-direction:column;
    `}
  }
  p {
    ${props => props.theme.media.mediumPlus`
      padding:0;
    `}
  }
`
export const SponsorLogo = styled.img``

export const RsxContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130%;
  /* margin-left: 37px; */

  ${props => props.theme.media.mediumPlus`
    width:30%;
    margin-left:0;
  `}

  a {
    position: relative;
    bottom: 0;
    transition: all 400ms;

    &:hover {
      transition: all 400ms;
      bottom: 3px;
    }
  }
`
