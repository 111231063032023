import styled from "styled-components"
import { StandardBtn } from "../../styled"

export const Container = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  padding: 30px 45px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: space-between;

  ${props => props.theme.media.mediumPlus`
    padding: 15px;
    flex-wrap:wrap;
    flex-direction: column;
     transform: translateY(0) !important;
    &>div {
      margin: 20px auto 0 auto;
    }
  `}
`

export const Arrow = styled.img`
  ${props => props.theme.media.mediumPlus`
  position:absolute;
  left:15px;
  top:20px;
  `}
`
export const PurchaseHeaderTitle = styled.h1``

export const AgorastoreLogo = styled.img`
  width: 190px;

  ${props => props.theme.media.mediumPlus`
  display:none;
  `}
`
export const AgorastoreLogoMobile = styled.img`
  width: 190px;
  display: none;
  ${props => props.theme.media.mediumPlus`
  display:block;
  `};
`
export const Back = styled.p`
  color: ${props => props.theme.colors.blue};
  margin-left: 14px;
  align-items: center;

  ${props => props.theme.media.mediumPlus`
    display:none;
  `}
`
export const BackToWebsite = styled(StandardBtn)`
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  margin: 0;

  &:hover {
    transition: all 400ms;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue};
  }
`
