import styled from "styled-components"

import { StandardBtn } from "../../styled"
const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const Container = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  padding: 30px 45px;
  background-color: ${currentColor};
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: space-between;

  ${props => props.theme.media.mediumPlus`
    background-color: ${props => props.hideOnScrollDown && "white"};
    transform: ${props =>
      props.hideOnScrollDown ? "translateY(-190px)" : "translateY(0)"};
      transition: all 600ms;
      padding:30px 0 0 0;
      flex-direction:column;
  `}
`
export const PurchaseHeaderTitle = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: 35px;
  line-height: 40px;
  padding: 0 50px;
  margin: 0;
  max-width: 920px;

  ${props => props.theme.media.mediumPlus`
    font-size: 20px;
    line-height: 32px;
    padding:20px 15px;
    color: ${currentColor};
    text-align:center;
  `}
`
export const AgorastoreLogo = styled.img`
  width: 170px;

  ${props => props.theme.media.mediumPlus`
     opacity: ${props => (props.hideOnScrollDown ? "0" : "1")};
     transition: all 700ms;
  `}
`
export const Back = styled.p`
  color: ${props => props.theme.colors.blue};
  margin-left: 14px;
  align-items: center;
`

export const BackToWebsite = styled(StandardBtn)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: transparent;
  white-space: nowrap;
`

export const ListBtn = styled(StandardBtn)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: transparent;
  right: 2px;
  position: relative;
  white-space: nowrap;
`

export const Ctas = styled.div`
  display: flex;
  align-items: center;

  ${props => props.theme.media.mediumPlus`
    justify-content:center;
    margin-bottom:20px;
        opacity: ${props => (props.hideOnScrollDown ? "0" : "1")};
     transition: all 700ms;
  `}

  span {
    border-left: 0.5px solid ${props => props.theme.colors.white};
    height: 30px;
    right: 1px;
    position: relative;
  }
`

export const PurchaseHeaderContainer = styled.div`
  ${props => props.theme.media.mediumPlus`
    width:100%;
    background-color: ${props => props.theme.colors.white};
    color: ${currentColor}
  `}
`

export const RightPart = styled.div`
  display: flex;
  width: 84%;
  justify-content: space-between;

  ${props => props.theme.media.mediumPlus`
    flex-direction:column-reverse;
    margin-top:30px;
    width:100%;
  `}
`
