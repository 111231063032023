import styled from "styled-components"

// General

const currentColor = props =>
  props.categories === "Immobilier"
    ? props.theme.colors.green
    : props.theme.colors.yellow

export const StandardBtn = styled.button`
  border: 1px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  background-color: transparent;
  border-radius: 50px;
  padding: 8px 20px;
  font-weight: bold;
  font-size: 12px;
  transition: all 600ms;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.white};
    color: ${currentColor};
    transition: all 400ms;
  }
`
export const Cta = styled.button``
export const Wrapper = styled.div`
  max-width: ${props => (props.isLarge ? "1240px" : "1200px")};
  margin: 0 auto;
  padding: 55px 0;
  position: relative;
  ${props => props.theme.media.mediumPlus`
  padding:15px;
   `}
`

// Header

// Summary

export const SummaryTitle = styled.h2``
export const SummaryLabor = styled.li``

// Typos

export const Title = styled.h1``
export const SubTitle = styled.h2``
export const Labor = styled.p``
export const SectionTitle = styled.h2``

// Styling

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.alignItemsCenter && "center"};
  justify-content: ${props => props.spaceBetween && "space-between"};
`
