import React from "react"
import {
  Container,
  Logo,
  SubContainer,
  SponsorLogo,
  SponsorContainer,
  RsxContainer,
} from "./styled"
import { Row } from "../../styled"
import { useMocks } from "../../context"

import logo from "./../../images/logo.png"
import idf from "./../../images/idf.svg"
import label from "./../../images/label.png"

import fb from "./../../images/fb.svg"
import tw from "./../../images/tw.svg"
import lk from "./../../images/lk.svg"

const links = [
  {
    img: fb,
    url: "https://www.facebook.com/AgoraStore/",
  },
  { img: tw, url: "https://twitter.com/AgoraStore/" },
  {
    img: lk,
    url: "https://www.linkedin.com/company/agorastore/s",
  },
]

const Footer = props => {
  const { footer } = useMocks()

  return (
    <Container>
      <Logo src={logo} alt="Logo Agorastore" />
      <p>{footer.text}</p>

      <SubContainer>
        <Row spaceBetween>
          <SponsorContainer>
            <SponsorLogo src={idf} alt="Sponsor Logo" />
            <p>
              {footer.catchlineSponsor} <img src={label} alt="Sponsor label" />
            </p>
          </SponsorContainer>
          <hr />
          <div>
            <p>{footer.rsx.title}</p>

            <RsxContainer>
              {links.map((el, i) => {
                return (
                  <a
                    href={el.url}
                    target="_blank"
                    rel="noopener, noreferrer"
                    key={i}
                  >
                    <img src={el.img} alt={el.url} />
                  </a>
                )
              })}
            </RsxContainer>
          </div>
        </Row>
      </SubContainer>
    </Container>
  )
}
export default Footer
