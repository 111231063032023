import React from "react"
import PropTypes from "prop-types"

import Header from "../header"
import Footer from "../footer"
import PurchaseGuideHeader from "../purchaseGuideHeader"
import "./layout.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}
const Layout = props => {
  return (
    <div>
      {props.isHomePage ? (
        <PurchaseGuideHeader />
      ) : (
        <Header
          categories={props.categories}
          purchaseGuideTitle={props.purchaseGuideTitle}
        />
      )}
      <div
        style={{
          margin: `0 auto`,
          paddingTop: props.isHomePage
            ? "10px"
            : props.hadSummary
            ? "0px"
            : "110px",
          backgroundColor: props.isHomePage ? "#F4F4F4" : "#FFFFFF",
        }}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
